<template>
    <div class="h100">
      <el-card class="box-card">
        <avue-crud v-bind="bindVal"
                  v-on="onEvent"
                  :page.sync="page"
                  @search-reset="searchChange"
                  @search-change="searchChange"
                  v-model="form">
                  <template slot="bindStatusForm"
                    slot-scope="{value}">
                    {{value?'解绑':'绑定'}}
                  </template>
                  <template slot="tblCarTraceListForm"
                    slot-scope="{value}">
                    <!-- {{value}} -->
                      <template v-for="image in value">
                        <el-row>
                          <el-col :span="8">
                            <el-image
                            style="width: 200px; height: 200px"
                            :src="imgUrl+image.url.replace('/root/file','')"
                            fit="contain"></el-image>
                          </el-col>
                          <el-col :span="16">
                            <div>摄像头：{{image.videoName}}</div>
                            <div>图片名称：{{image.url.split('/')[image.url.split('/').length-1]}}</div>
                            <div>图片Id：{{image.pictureId}}</div>
                            <div>车辆Id：{{image.carId}}</div>
                            <div>globalId：{{image.globalId}}</div>
                          </el-col>
                        </el-row>
                      </template>
                    </template>
                    <template slot-scope="{type,size,row,index}" slot="menu">
                        <el-button icon="el-icon-view" :size="size" :type="type" @click="view(row)">查 看</el-button>
                      </template>
        </avue-crud>
        <el-dialog title="操作详情" :visible.sync="dialogFormVisible" @close="closeWindow">
          <el-form :model="form" ref="ruleForm" label-width="80px">
            <el-form-item label="操作动作:" prop="bindStatus">
              {{form.bindStatus?'解绑':'绑定'}}
            </el-form-item>
            <el-form-item label="操作时间:" prop="createTime">
              {{form.createTime}}
            </el-form-item>
            <el-form-item label="相关数据:" prop="tblCarTraceList">
              <el-row>
                <el-col :span="8" v-for="(image,imageindex) in form.tblCarTraceList" :key="image.pictureId">
                  <el-card :body-style="{ padding: '20px',lineHeight:'20px' }">
                    <el-image
                      style="width: 200px; height: 200px"
                      :src="imgUrl+image.url.replace('/root/file','')"
                      :preview-src-list="[imgUrl+image.url.replace('/root/file','')]"
                      :initial-index="imageindex"
                      fit="contain"></el-image>
                      <div>摄像头：{{image.videoName}}</div>
                      <div>图片名称：{{image.url.split('/')[image.url.split('/').length-1]}}</div>
                      <div>图片Id：{{image.pictureId}}</div>
                      <div>车辆Id：{{image.carId}}</div>
                      <div>globalId：{{image.globalId}}</div>
                  </el-card>
                </el-col>
              </el-row>
                
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="closeWindow">关闭</el-button>
          </div>
        </el-dialog>
      </el-card>
    </div>
  </template>
  
  <script>
  import {list} from '@/api/carTrace/carBindRecord'
  export default window.$crudCommon({
    data() {
      return {
        dialogFormVisible:false,
        dialogFormEdit:true,
        userOpenId:null,
        currentUser:null,
        dataRecord:[],
        dataRecordLoading:false,
        imgUrl:process.env.VUE_APP_BASE_API,
        formImgList:[],
        selectedIndex:0, 
      }
    },
    methods: {
      viewImgList(index){
        this.selectedIndex = Number(index)
      },
      closeWindow(){
        this.dialogFormVisible = false;
        this.form = {
            name:'',
            organization:'',
            content:''
          }
          this.formImgList=[]
      },
      view(row){
        this.form = row;
        //图片预览操作
        this.form.tblCarTraceList.map(item=>{
          this.formImgList.push(this.imgUrl+item.url.replace('/root/file',''))
        })
        
        this.dialogFormVisible = true;
      },
      searchChange(params, done){
        if (done) done();
        const data = Object.assign({
            pageNum: this.page.currentPage,
            pageSize: this.page.pageSize,
          }, params)
          list(data).then(res => {
            this.loading = false;
            this.page.total = res.data.total;
            const data = res.data.records;
            this.data = data;
          })
      },
      getList () {
          this.loading = true;
          const data = Object.assign({
            pageNum: this.page.currentPage,
            pageSize: this.page.pageSize,
          }, this.params)
          list(data).then(res => {
            this.loading = false;
            this.page.total = res.data.total;
            const data = res.data.records;
            this.data = data;
          })
        },
      },
  }, {
    name: '/carTrace/carBindRecord',
    res: (data) => {
      return {
        total: data.total,
        data: data.records
      }
    },
    pageNum: 'page',
    pageSize: 'size'
  })
  </script>
  
  <style lang="scss" scoped>
  </style>
